import Swiper, { Navigation } from 'swiper';

import "swiper/scss";
import "swiper/scss/navigation";

class ProjectsCarousel {
  constructor() {
    this.carousels = document.querySelectorAll("[data-projects-carousel]");
  }

  initProjectsCarousel() {

    if (!this.carousels.length) return;

    this.carousels.forEach((carousel) => {
      // const totalSlides = carousel.dataset.projectsCarouselTotal;
      const carouselWrapper = carousel.parentElement;
      const nextButton = carouselWrapper.querySelector('.swiper-button-next')
      const prevButton = carouselWrapper.querySelector('.swiper-button-prev')
      const slidesPerView = [1, 2, 2];
      const slidesPerGroup = [1, 1, 1];

      const swiper = new Swiper(carousel, {
        modules: [Navigation],
        slidesPerView: slidesPerView[0],
        slidesPerGroup: slidesPerGroup[0],
        spaceBetween: 30,
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
        breakpoints: {
          768: {
            slidesPerView: slidesPerView[1],
            slidesPerGroup: slidesPerGroup[1],
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: slidesPerView[2],
            slidesPerGroup: slidesPerGroup[2],
            spaceBetween: 40,
          },
        },
      });

    });

  }

  init() {
    this.initProjectsCarousel();
  }
}

const carousels = new ProjectsCarousel();
carousels.init();
