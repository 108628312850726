import Swiper, { Navigation, Pagination } from 'swiper';

import "swiper/scss";
import "swiper/scss/navigation";

class LogoCarousel {
  constructor() {
    this.carousels = document.querySelectorAll("[data-logos-carousel]");
  }

  initLogoIconsCarousel() {

    if (!this.carousels.length) return;

    this.carousels.forEach((carousel) => {
      const totalSlides = carousel.dataset.logosCarouselTotal;
      const carouselWrapper = carousel.parentElement;
      const nextButton = carouselWrapper.querySelector('.swiper-button-next')
      const prevButton = carouselWrapper.querySelector('.swiper-button-prev')

      let slidesPerView, slidesPerGroup
      if(totalSlides > 5) {
        slidesPerView = [2, 5, 7];
        slidesPerGroup = [1, 2, 3];
      } else {
        slidesPerView = [3, 3, 3];
        slidesPerGroup = [1, 1, 1];
      }

      const swiper = new Swiper(carousel, {
        modules: [Navigation],
        slidesPerView: slidesPerView[0],
        slidesPerGroup: slidesPerGroup[0],
        spaceBetween: 20,
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
        breakpoints: {
          768: {
            slidesPerView: slidesPerView[1],
            slidesPerGroup: slidesPerGroup[1],
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: slidesPerView[2],
            slidesPerGroup: slidesPerGroup[2],
            spaceBetween: 40
          },
        },
      });
    });

  }

  init() {
    this.initLogoIconsCarousel();
  }
}

const carousels = new LogoCarousel();
carousels.init();
