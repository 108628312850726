import 'fslightbox';

class SingleVideo {
  constructor() {
  }
  init() {
  }
}

const singleVideo = new SingleVideo();
singleVideo.init();
