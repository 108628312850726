export const publicationsQuery = `
{
  entries(section: "site__publications") {
    slug
    title
    ... on site__publications_publication_entry_Entry {
      id
      text: card__summary
      cardDate: common__date @formatDateTime(format: "d M, Y")
      cardImage: card__image {
        id
        url @transform(width: 600, height: 600, immediately: true)
      }
      cardDownload: publication__publication {
        id
        url
        extension
      }
      categories: publication__type {
        id
        title
        uri
      }
      cardUrl: publication__link {
        ariaLabel
        customText
        target
        text
        title
        type
        url
      }
    }
  }
}
`;
